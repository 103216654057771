.book-page {
  padding: 20px;
  text-align: center;
  background-color: rgb(250, 249, 243);
  min-height: 100vh;
}

.book-details {
  margin-bottom: 20px;
}

.book-cover {
  max-width: 200px;
  border-radius: 8px;
}

.chapter-content {
  margin-top: 20px;
}

.menu-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #282c34;
  padding: 10px;
  display: flex;
  justify-content: center;
}

.menu-bar button {
  border: "3px solid #282c34";
  padding: 6px;
  padding-bottom: 2px;
  color: #000;
  cursor: pointer;
  font-size: 18px;
  border-radius: 5px;
}

.menu-bar button:hover {
  background-color: #21a1f1;
}

.chapter-list {
  background-color: white;
  padding: 20px;
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  z-index: 1;
  padding-bottom: 200px;
}

.chapter-item {
  margin: 10px 0;
  cursor: pointer;
  font-size: large;
  border: 2px solid #282c34;
  border-bottom: 3px solid #282c34;
  padding: 8px;
  border-radius: 15px;
  width: calc(100vw - 80px);
}

.chapter-item:hover {
  text-decoration: underline;
}
