.library {
  padding: 20px;
  text-align: center;
}

.bookshelf {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.book {
  width: 200px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.book:hover {
  transform: translateY(-5px);
}

.book-cover {
  width: 100%;
  height: auto;
}

.book-title {
  font-size: 18px;
  margin: 10px 0;
}

.book-author {
  font-style: italic;
  color: #555;
}

.book-summary {
  padding: 0 10px 10px;
  color: #333;
}
